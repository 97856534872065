<template>
  <h2 class="display-2">Total Chain Report</h2>
  <hr/>
  <div class="d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" style="width:10rem;height:10rem;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <template v-else>
    <div class="row mb-3">
      <h3>Total Data</h3>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">Hits Made</div>
          <div class="card-body">{{ total.hits.toLocaleString() }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">Non Milestone Respect</div>
          <div class="card-body">{{ total.resp.toLocaleString([],{minimumFractionDigits:2,maximumFractionDigits:2}) }}</div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">Total Respect</div>
          <div class="card-body">{{ total.tot.toLocaleString([],{minimumFractionDigits:2,maximumFractionDigits:2}) }}</div>
        </div>
      </div>
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg></span>
      <input type="text" class="form-control" placeholder="Username" aria-label="Username" v-model="filter" />
    </div>
    <select class="d-md-none form-select mb-3" v-model="mobileForce" :class="{'text-muted':mobileForce}">
      <option :value='null' class="text-muted">No Filter</option>
      <option v-for='(name,index) in header.slice(1)' :key="index+name" :value="index+1">{{name.toString()}}</option>
    </select>
    <div class="overflow">
      <table class="table" >
        <thead>
          <tr style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
            onselectstart="return false;" onmousedown="return false;" unselectable="on">
            <th class="mobileFocus">#</th>
            <th v-for="(name,index) in header" :class="{'mobileFocus': index===0 || index === (mobileForce||1) }" :key="index+name" @click="sortButton(index,$event)"><div>{{name.toString()}}</div></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(each,row_index) in data" :key="each">
            <td class="mobileFocus">{{row_index+1}}</td>
            <td v-for="(entry,col_index) in each" :class="{'mobileFocus': col_index===0 || col_index === (mobileForce||1) }" :key="entry" :data-label="header[col_index].valueOf()">{{entry}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
</template>
<style lang="scss" scoped>
  @import "bootstrap/scss/functions";
  @import "bootstrap/scss/variables";
  @import "bootstrap/scss/mixins";
  @import "bootstrap/scss/utilities";
  .ascend::after{
    content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-up-fill' viewBox='0 0 16 16'%3E%3Cpath d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/%3E%3C/svg%3E");
  }
  .descend::after{
    content:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  }
  .overflow {
    overflow: auto;
    width:100%;
  }
  @include  media-breakpoint-down(md) {
    table {
      th,td {
        display: none;
        &.mobileFocus {
          display: table-cell;
        }
      }
    }
  }
</style>
<script type="text/javascript">
  import fetch from '@/fetch'
  class Sortable {
    constructor(string,sort) {
      this.string = string;
      this.sort = sort;
    }
    toString() {
      return this.string;
    }
    valueOf(){
      return this.sort;
    }
  }
  class Short {
    constructor(s,a){
      this.s = s;
      this.a = a;
    }
    toString() {
      return this.s;
    }
    valueOf(){
      return this.a;
    }
  }
  export default {
    components:{
    },
    data () {
      return {
        loading: false,
        msg: null,
        code:0,
        rawData:[],
        sortColumn:null,
        sortDesc:null,
        filter:'',
        mobileForce:null,
        mobileForceMenu:false
      }
    },
    computed:{
      header () {
        return [
          'Name',
          new Short('Total Hits','Hits'),
          new Short('Minimum Hits Required','Min Hit Req'),
          'Xanax Consumed',
          'Refill Consumed',
          'Missing Hits (DP)',
          'Miss Hits (NDP)',
          new Short('Average Respect','Avg Resp'),
          'Total Respect',
          'Carnage',
          'Massacre',
          'Genocide'
        ];
      },
      data () {
        const n = v=>v?v:0;
        const test = (bool,combined) => bool ? new Sortable('Y',combined) : new Sortable('N',combined);
        return this.rawData.map(row=>{
          const [name, pid, tot_hits, min_hits, xanax, points, tot_res, carnage, massacre, genocide,,avg_res] = row;
          const refill = points/25;
          return [
            new Sortable(`${name} [${pid}]`, name), //Name
            n(tot_hits), // Total Hits
            min_hits> 0 ? new Sortable(min_hits,-min_hits) : new Sortable('Achieved',-min_hits),//`Achieved${-1*min_hits}`,
            xanax,
            refill,
            Math.max(xanax*10+refill*6-tot_hits,0),
            Math.max(xanax*10+refill*4-tot_hits,0),
            avg_res,
            tot_res,
            test(carnage, genocide+massacre+carnage),
            test(massacre, genocide+massacre+carnage),
            test(genocide, genocide+massacre+carnage),
          ]
        }).filter(e=>{
          if (this.filter.length === 0)
            return true;
          return e[0].valueOf().toLowerCase().includes(this.filter.toLowerCase());
        }).sort((a,b)=>{
          const [x,y] = (this.sortColumn != null ? this.sortDesc:true)?[b,a]:[a,b];
          let sort = this.sortColumn == null ? 1 : this.sortColumn;
          const numSort = x[sort] - y[sort];
          if (numSort === 0)
            sort = 0;
          if (x[sort] && y[sort] && typeof x[sort].valueOf() === 'string' && typeof y[sort].valueOf() === 'string' )
            return x[sort].valueOf().localeCompare(y[sort].valueOf(),'en',{ sensitivity: 'base', ignorePunctuation: true });
          return numSort;
        }).map(row=>row.map((e,i)=>{
          if (typeof e === 'number')
            return e.toLocaleString([],i===7?{
              minimumFractionDigits:2,
              maximumFractionDigits:2
            }:{});
          if (e === null)
            return 'No Value';
          return e?.toString();
        }));
      },
      total () {
        const [hits,resp,tot] = this.rawData.reduce(([oHit,oRes,oTot],[,,nHit,,,,nRes,,,,nTot])=>[oHit+nHit,oRes+nRes,oTot+nTot],[0,0,0]);
        return {hits,resp,tot};
      }
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetchData();
    },
    watch:{
      mobileForce(input) {
        const val = input || 1;
        console.log(val);
      }
    },
    methods: {
      async fetchData () {
        this.settings = {};
        this.code = this.msg = null;
        this.loading = true;
        fetch('/api/v1/chain/total',{method:'get'})
          .then(e=>{
            this.rawData = e.arr;
          }).catch(error=>{
            this.msg = {error};
          }).finally(()=>this.loading = false);
        // replace `getPost` with your data fetching util / API wrapper
      },
      sortButton(i,event) {

        const target = event.target.closest('TH').classList;
        if (this.sortColumn == i) {
          if (this.sortDesc) {
            target.remove('descend');
            this.sortDesc = false;
            this.sortColumn = null;
          } else {
            this.sortDesc = true;
            target.add('descend');
            target.remove('ascend');
          }
        } else {
          document.querySelectorAll('.ascend,.descend')
            .forEach(e=>e.classList.remove('ascend','descend'));
          target.add('ascend');
          this.sortColumn = i;
          if (this.sortDesc)
            this.sortDesc = false;
        }
      }
    }
  }
</script>
